// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import FooterInfo from "components/app-info/FooterInfo";
import ComxInteractiveScreen from "./components/comx-interactive/ComxInteractiveScreen";
import { ComxInteractiveScreenContextProvider } from "./contexts/comxInteractiveScreenContext";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <ScrollToTop />
            <ComxInteractiveScreenContextProvider>
              <I18nextProvider i18n={i18next}>
                <Router />
              </I18nextProvider>
              <ComxInteractiveScreen />
            </ComxInteractiveScreenContextProvider>
            <FooterInfo />
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
