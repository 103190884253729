import parsePhoneNumber from "libphonenumber-js";
import { getEthnicityFromPatient, getRaceFromPatient } from "./fhirUtils";
import {
  getBirthCertificateNumberFromHealthcare,
  getDriverLicenseNumberFromHealthcare,
  getNPIFromHealthcare,
  getSocialSecurityNumberFromHealthcare,
} from "./healthcareUtils";

const findMRN = (item: any) => {
  let mrn = "";
 if (item?.identifier && item?.identifier.length > 0){
 mrn = item.identifier.find((i: any) => {
   return i?.type?.text === "MRN"
  })?.value
 }
 return mrn
}

export const formatPatient = (item: any) => {
  //console.log(item.practitioner);
  return {
    id: item?.id,
    active: item?.active,
    mrn: findMRN(item),
    address: item?.address
      ? {
          line:
            (item?.address?.[0]?.line &&
              item?.address?.[0]?.line &&
              item?.address?.[0]?.line[0]) ||
            "",
          line_2:
            (item?.address?.[0]?.line &&
              item?.address?.[0]?.line &&
              item?.address?.[0]?.line[1]) ||
            "",
          use: item?.address?.[0].use || "",
          city: item?.address?.[0]?.city || "",
          state: item?.address?.[0]?.state || "",
          postalCode: item?.address?.[0]?.postalCode || "",
        }
      : {},
    birthDate: item?.birthDate || "",
    gender:
      item?.gender?.charAt(0).toUpperCase() + item?.gender?.slice(1) || "",
    name: {
      family: item?.name?.[0]?.family || "",
      given: Array.isArray(item?.name?.[0]?.given)
        ? item?.name?.[0]?.given?.[0]
        : item?.name?.[0]?.given,
      middle_name: Array.isArray(item?.name?.[0]?.given)
        ? item?.name?.[0]?.given[1]
        : "",
      suffix: item?.name?.[0]?.suffix || "",
    },
    resourceType: item?.resourceType,
    telecom_value: (() => {
      const defaultRes = [
        {
          phoneType: "",
          phone: "",
          isDefault: true,
        },
      ];

      if (item.telecom === undefined) {
        return defaultRes;
      }

      const res = item.telecom
        .filter((val: any) => val["system"] === "phone")
        .map((val: any) => {
          return {
            phoneType: val["use"] === undefined ? "" : val["use"].toUpperCase(),
            phone: val["value"] === undefined ? "" : val["value"],
            isDefault: val["rank"] === 1,
          };
        });

      if (Array.isArray(res) && res.length > 0) return res;

      return defaultRes;
    })(),
    email: (() => {
      const defaultRes = [
        {
          emailType: "",
          email: "",
          isDefault: true,
        },
      ];

      if (item.telecom === undefined) {
        return defaultRes;
      }

      const res = item.telecom
        .filter((val: any) => val["system"] === "email")
        .map((val: any) => {
          return {
            emailType: val["use"] === undefined ? "" : val["use"].toUpperCase(),
            email: val["value"] === undefined ? "" : val["value"],
            isDefault: val["rank"] === 1,
          };
        });

      if (Array.isArray(res) && res.length > 0) return res;

      return defaultRes;
    })(),
    languages: (() => {
      const defaultRes = [
        {
          language: "",
          isDefault: true,
        },
      ];

      if (item.communication === undefined) {
        return defaultRes;
      }

      const res = item.communication.map((val: any) => {
        return {
          language:
            val["language"]["text"] === undefined
              ? ""
              : val["language"]["text"],
          isDefault: val["preferred"],
        };
      });

      if (Array.isArray(res) && res.length > 0) return res;

      return defaultRes;
    })(),
    birth_certificate_number: (() => {
      if (item?.identifier === undefined || item?.identifier.length < 1)
        return "";

      const birthCertificateNumber = getBirthCertificateNumberFromHealthcare(
        item?.identifier
      );

      if (
        birthCertificateNumber === undefined ||
        birthCertificateNumber.value === undefined
      )
        return "";

      return birthCertificateNumber.value;
    })(),
    social_security_number: (() => {
      if (item?.identifier === undefined || item?.identifier.length < 1)
        return "";

      const socialSecurityNumber = getSocialSecurityNumberFromHealthcare(
        item?.identifier
      );

      if (
        socialSecurityNumber === undefined ||
        socialSecurityNumber.value === undefined
      )
        return "";

      return socialSecurityNumber.value;
    })(),
    driver_license_number: (() => {
      if (item?.identifier === undefined || item?.identifier.length < 1)
        return "";

      const driverLicenseNumber = getDriverLicenseNumberFromHealthcare(
        item?.identifier
      );

      if (
        driverLicenseNumber === undefined ||
        driverLicenseNumber.value === undefined
      )
        return "";

      return driverLicenseNumber.value;
    })(),
    patient_race: getRaceFromPatient(item) || "",
    patient_ethnicity: getEthnicityFromPatient(item) || "",
    contact_name:
      (item?.contact && item?.contact?.[0]["name"]["given"][0]) || "",
    contact_middle_name:
      (item?.contact && item.contact?.[0]?.["name"]?.["given"]?.[1]) || "",
    contact_family:
      (item?.contact && item?.contact?.[0]?.["name"]?.["family"]) || "",
    contact_email:
      (item?.contact && item?.contact?.[0]?.["telecom"]?.[0]?.["value"]) || "",
    contact_phone:
      (item?.contact && item?.contact?.[0]?.["telecom"]?.[1]?.["value"]) || "",
    contact_line1:
      (item?.contact && item.contact?.[0]?.["address"]?.["line"]?.[0]) || "",
    contact_line2:
      (item?.contact && item?.contact?.[0]?.["address"]?.["line"]?.[1]) || "",
    contact_city:
      (item?.contact && item?.contact?.[0]?.["address"]?.["city"]) || "",
    contact_state:
      (item?.contact && item.contact?.[0]?.["address"]?.["state"]) || "",
    contact_postal:
      (item?.contact && item?.contact?.[0]?.["address"]?.["postalCode"]) || "",
    practitioner_name:
      (item?.practitioner &&
        item?.practitioner?.["name"]?.[0]?.["given"]?.[0]) ||
      "",
    practitioner_middle_name:
      (item?.practitioner &&
        item?.practitioner?.["name"]?.[0]?.["given"]?.[1]) ||
      "",
    practitioner_family:
      (item?.practitioner && item?.practitioner?.["name"]?.[0]?.["family"]) ||
      "",
    practitioner_email:
      (item?.practitioner && item?.practitioner?.["telecom"]?.[0]?.["value"]) ||
      "",
    practitioner_phone:
      (item?.practitioner && item?.practitioner?.["telecom"]?.[1]?.["value"]) ||
      "",
    practitioner_street1:
      (item?.practitioner &&
        item?.practitioner?.["address"]?.[0]?.["line"] &&
        item?.practitioner?.["address"]?.[0]?.["line"]?.[0]) ||
      "",
    practitioner_street2:
      (item?.practitioner &&
        item?.practitioner?.["address"]?.[0]?.["line"] &&
        item?.practitioner?.["address"]?.[0]?.["line"]?.[1]) ||
      "",
    practitioner_city:
      (item?.practitioner && item?.practitioner?.["address"]?.[0]?.["city"]) ||
      "",
    practitioner_state:
      (item?.practitioner && item?.practitioner?.["address"]?.[0]?.["state"]) ||
      "",
    practitioner_postal_code:
      (item?.practitioner &&
        item?.practitioner?.["address"]?.[0]?.["postalCode"]) ||
      "",
    practitioner_facility:
      (item?.practitioner && item?.practitioner?.["address"]?.[0]?.["text"]) ||
      "",
    practitioner_npi: (() => {
      if (item?.practitioner?.identifier !== undefined) {
        const npi = getNPIFromHealthcare(item?.practitioner?.identifier);

        if (npi !== undefined) return npi;
      }

      return "";
    })(),
    managing_organization:
      (item?.managingOrganization && item?.managingOrganization?.["display"]) ||
      "",
    organizationname:
      item?.organizationname || item?.managingOrganization?.["display"] || "",
    observation:
      (item?.observation && item?.observation?.["value_observation"]) || "",
    observation_sil:
      (item?.observations_sil && item?.observations_sil.length > 0
        ? item.observations_sil[0]["value_observation"]
        : "") || "",
    observation_pmca:
      (item.observations_pmca && item.observations_pmca.length > 0
        ? item.observations_pmca[0]["value_observation"]
        : "") || "",
    observation_socialcomplex:
      (item.observations_socialcomplex &&
      item.observations_socialcomplex.length > 0
        ? item.observations_socialcomplex[0]["value_observation"]
        : "") || "",
  };
};

export const enforcePhoneNumberPattern = (phone: string) => {
  console.log("number to enforce: ", phone);
  let newString = phone.match(/[0-9]{0,14}/g);

  if (newString === null) {
    return "";
  }

  // Join parts returned from RegEx match
  newString = newString.join("");

  // Start number with "+"
  newString = "+" + newString;

  if (newString[0].includes("1")) {
    newString = "+" + newString;
  } else {
    newString = "+1" + newString;
  }

  // Limit length to 15 characters
  newString = newString.substring(0, 15);

  return newString;
};

export const getContactDataFromPatient = (patientData: any) => {
  let contactData = { phone: null, email: null };
  if (patientData?.telecom) {
    const emailAddresses = patientData.telecom.filter((t: any) => {
      return t?.system === "email";
    });
    const phoneNumbers = patientData.telecom.filter((t: any) => {
      return t?.system === "phone";
    });

    if (emailAddresses && emailAddresses.length > 0) {
      contactData = { ...contactData, email: emailAddresses[0] };
    }
    if (phoneNumbers && phoneNumbers.length > 0) {
      contactData = {
        ...contactData,
        phone: phoneNumbers[0],
      };
    }
  }
  return contactData;
};

export const getContactDataFromPatientForComx = (patientData: any) => {
  let contactData = getContactDataFromPatient(patientData);
  const email = contactData?.email?.value;
  const phone = contactData?.phone?.value;

  if (email) {
    contactData = { ...contactData, email };
  }
  if (phone) {
    const formattedPhone = parsePhoneNumber(phone, "US");
    contactData = { ...contactData, phone: formattedPhone?.number };
  }
  return contactData;
};
