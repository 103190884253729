// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import {
  AssignmentOutlined,
  BusinessOutlined,
  FaceOutlined,
  PeopleOutline,
  ConnectWithoutContact,
  FormatListBulleted,
} from "@material-ui/icons";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: <PeopleOutline />,
  orders: <AssignmentOutlined />,
  tasks: <FormatListBulleted />,
  organizations: <BusinessOutlined />,
  users: <FaceOutlined />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  connectWithoutContact: <ConnectWithoutContact />,
  incident: <WarningAmberIcon />,

};

const sidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      {
        title: 'Patients',
        path: PATH_DASHBOARD.app.patients,
        icon: ICONS.user,
      },
      {
        title: 'Request',
        path: PATH_DASHBOARD.app.request,
        icon: ICONS.orders,
      },
      {
        title: 'Tasks',
        path: PATH_DASHBOARD.app.tasks,
        icon: ICONS.tasks,
      },
      {
        title: 'Organizations',
        path: PATH_DASHBOARD.app.organizations,
        icon: ICONS.organizations,
      },
      {
        title: 'Users',
        path: PATH_DASHBOARD.app.users,
        icon: ICONS.users,
      },
      {
        title: 'Patient Engagement',
        path: PATH_DASHBOARD.app.patientEngagements,
        icon: ICONS.connectWithoutContact
      },
      {
        title: 'Incident',
        path: PATH_DASHBOARD.app.falloutscreen,
        icon: ICONS.incident

      },
    ]
  }
];

export default sidebarConfig;
